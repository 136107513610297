import React from "react";
import styles from "./CurrentNews.module.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Image } from "antd";

export default function CurrentNews() {
  const { link } = useParams();
  const select = useSelector((state) => state.newsSlider);

  const item_news_wrapper = {
    height: "max-content",
    background: "rgba(41, 53, 21, 0.8)",
    margin: "20px 40px",
    border: "1px solid black",
    color: "white",
    borderRadius: "10px",
  };

  const img_wrapper = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    padding: "20px",
  };

  const img = {
    margin: "10px",
  };

  let render = function (i) {
    return (
      <div className={styles.news_main_wrapper}>
        <div style={item_news_wrapper}>
          <div style={img_wrapper}>
            {select[i].img.map((item) => (
              <div style={img}>
                <Image
                  key={item.id}
                  alt=""
                  src={item}
                  // width={300}
                  height={200}
                />
              </div>
            ))}
          </div>
          <h5 className={styles.news_date}>{select[i].date}</h5>
          <p className={styles.news_text}>{select[i].text}</p>
        </div>
      </div>
    );
  };

  let res;

  switch (link) {
    case "19.01.2024":
      res = render(0);
      break;

    case "24.01.2024":
      res = render(1);
      break;

    case "03.02.2024":
      res = render(2);
      break;

    case "16.02.2024":
      res = render(3);
      break;

    case "29.02.2024":
      res = render(4);
      break;

    case "02.03.2024":
      res = render(5);
      break;

    case "02.03.2024.":
      res = render(6);
      break;

    case "05.03.2024":
      res = render(7);
      break;

    case "25.03.2024":
      res = render(8);
      break;

    case "30.03.2024":
      res = render(9);
      break;

    case "13.04.2024":
      res = render(10);
      break;

    case "14.04.2024":
      res = render(11);
      break;

    case "15.04.2024":
      res = render(12);
      break;

    case "17.04.2024":
      res = render(13);
      break;

    case "17.04.2024.":
      res = render(14);
      break;

    case "18.04.2024":
      res = render(15);
      break;

    case "22.04.2024":
      res = render(16);
      break;

    case "28.04.2024":
      res = render(17);
      break;

    case "10.05.2024":
      res = render(18);
      break;

    case "10.05.2024.":
      res = render(19);
      break;

    case "28.05.2024":
      res = render(20);
      break;

    case "30.05.2024":
      res = render(21);
      break;

    case "14.06.2024":
      res = render(22);
      break;

    case "15.06.2024":
      res = render(23);
      break;

    case "23.06.2024":
      res = render(24);
      break;

    case "28.06.2024":
      res = render(25);
      break;

    case "30.06.2024":
      res = render(26);
      break;

    case "08.07.2024":
      res = render(27);
      break;

    case "19.07.2024":
      res = render(28);
      break;

    case "02.08.2024":
      res = render(29);
      break;

    case "29.08.2024":
      res = render(30);
      break;

    case "03.09.2024":
      res = render(31);
      break;

    case "12.09.2024":
      res = render(32);
      break;
   
      case "18.09.2024":
      res = render(33);
      break;
      
      case "28.09.2024":
      res = render(34);
      break;
     
      case "01.10.2024":
      res = render(35);
      break;
     
      case "26.10.2024":
      res = render(36);
      break;
      
      case "01.11.2024":
      res = render(37);
      break;
     
      case "04.11.2024":
      res = render(38);
      break;
      
      case "06.11.2024":
      res = render(39);
      break;
      
      case "09.11.2024":
      res = render(40);
      break;
      
      case "25.11.2024":
      res = render(41);
      break;
      
      case "29.11.2024":
      res = render(42);
      break;
      
      case "30.11.2024":
      res = render(43);
      break;
      
      case "10.12.2024":
      res = render(44);
      break;
      
      case "11.12.2024":
      res = render(45);
      break;
      
      case "15.12.2024":
      res = render(46);
      break;
      
      case "29.12.2024":
      res = render(47);
      break;
      
      case "07.01.2025":
      res = render(48);
      break;
      
      case "Юбилей руководителя Досугового центра 'Сильные духом'":
      res = render(49);
      break;
      
      case "19.01.2025":
      res = render(50);
      break;
      
      case "31.01.2025":
      res = render(51);
      break;
      
      case "17.02.2025":
      res = render(52);
      break;
     
      case "22.02.2025":
      res = render(53);
      break;
      
      case "25.02.2025":
      res = render(54);
      break;
      
      case "01.03.2025":
      res = render(55);
      break;
      
      case "09.03.2025":
      res = render(56);
      break;

    default:
  }

  return <>{res}</>;
}
